.card-container{
    width: 15rem;
    border-right: 1px solid #d2d2d2;
    border-top: 1px solid #d2d2d2;
    border-bottom: 1px solid #d2d2d2;
    border-radius: 1rem;
    box-shadow: 0px 10px 8px #bababa;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 0.8rem;
    background-color: #ffffff;
    height: fit-content;
    min-height: 5rem;
    cursor: grab;
    position: relative;
}

.card-container-drag{
    opacity: 0.8;
    box-shadow: 0px 10px 8px #292929;
}

.card-container-isDrag{
    cursor:default 
}

@media only screen and (max-width: 700px) {
    .card-container {
        width: 10rem;
    }
}

@media only screen and (max-width: 450px) {
    .card-container {
        width: 9rem;
    }
}

.card-alert-color{
    position: absolute;
    top: 0;
    left: 0;
    border-radius:1rem 0 0 1rem;
    position: relative;
    width: 12px;
    display: block;
    unicode-bidi: isolate;
    padding: 0;
    margin: 0;
}

.card-information{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
}
.card-title{
    font-family: 'Open Sans Bold';
    font-size: 0.85rem;
}
.card-title, .card-status{
    width: auto;
    margin: 0 !important;
}

.card-status{
    font-size: 0.75rem;
    font-family: 'Open Sans SemiBold';
}

.card-alert-icon{
    margin-right: 15px;
    cursor: var(--cursor);
}

.card-financial-icon{
    margin-right: 15px;
}

.card-menu-div{
    display: flex;
    visibility: hidden;
    justify-content: center;
    align-items: center;
    position: relative;
    width: fit-content;
    top: 0px;
    right: 0px;
    height:100%;
    cursor: pointer;
}

.card-menu-div .card-menu{
    margin-right: 0.5rem;
}

.card-alert{
    position: relative;
    display: inline-block;
}

.card-badge {
    position: absolute;
    top: -3px;
    right: 10px;
    background-color: #5B76B6FF;
    color: #ffffff;
    border-radius: 50%;
    min-width: 20px;
    min-height: 20px;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    cursor: pointer;
    white-space: nowrap;
    line-height: 1;
}

.card-compare-container{
    width: 22rem;
    border-right: 1px solid #d2d2d2;
    border-top: 1px solid #d2d2d2;
    border-bottom: 1px solid #d2d2d2;
    border-radius: 1rem;
    box-shadow: 0px 10px 8px #bababa;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 0.8rem;
    background-color: #ffffff;
    height: fit-content;
    min-height: 5rem;
    cursor: grab;
    position: relative;
}

.card-compare-status{
    font-size: 0.75rem;
    font-family: 'Open Sans SemiBold';
    border-bottom: 1px solid rgb(173, 173, 173);
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 15rem;
}

.compare-total {
    text-align: right;
}

.card-percentage{
    background-color: rgb(224, 222, 222);
    width: fit-content;
    font-size: 0.65rem;
    font-family: 'Open Sans SemiBold';
    margin-top: 1rem;
}

.compare-modal-dates-range{
    margin-bottom: 1rem;
    font-family: 'Open Sans Semibold';
    text-align: center;
}

.compare-modal-no-registers{
    font-family: 'Open Sans Semibold';
    text-align: center;
    font-style: italic;
}

@keyframes blink {
    0% {
        fill: var(--color);
    }
    50% {
        fill: #bb9a2d;
    }
    100% {
        fill: var(--color);
    }
}

.blinking-error {
    animation: blink 1s infinite;
}

@keyframes blinkBackground {
    0% {
        background-color: var(--color);
    }
    50% {
        background-color: #bb9a2d;
    }
    100% {
        background-color: var(--color);
    }
}

.blinking-background {
    animation: blinkBackground 1s infinite;
}