body, html {
    margin: 0;
    padding: 0;
    background: #ececef;
    height: auto;
    background-size: 100% auto;
    min-width: 100%;
    overflow-x: auto;
}

.btn{
    cursor: pointer;
    margin: auto;
    border: 0;
    padding: 0;
}

.table__icon{
    width: 20px;
    height: 20px;
    margin: auto;
    color:#6A6A6A;
}

.table__icon-qrcode{
    width: 30px;
    height: 30px;
    margin: auto;
    color:#6A6A6A;
}

.svg-icon{
    margin-top: 8px;
}

.row {
    margin-top: 10px;
}

.module__title{
    font-size: 2.5rem;
    color: #6A6A6A;
    font-family: 'Open Sans Semibold';
    font-weight: bold;
    text-shadow: 0.5px 0.5px 2px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 1679px) {
    .module__title {
        font-size: 1.5rem;
    }
  }

.module__input-search-icon{
    fill: #003865;
    margin-right: 10px;
}

.module__input-search{
    border: 0;
    background-color: #EBEBEB;
    border-radius: 15px;
    height: 35px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }

.module__input-search:focus{
    outline: none;
}

.module__input-white{
    border: 0;
    background-color: white;
    border-radius: 15px;
    height: 35px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }

.module__input-white:focus{
    outline: none;
}

.selectOption{
    border-bottom: 2px solid #003865;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    margin-top: 20px;
    width: 330px;
}

.input__label{
    color: #6A6A6A;
    font-family: 'Open Sans Semibold';
    font-size: 14px;
    margin-top: 15px;
}

.modal__subtitle{
    color: #6A6A6A;
    font-family: 'Open Sans Semibold';
    font-size: 18px;
}

.input__label_modal{
    font-family: 'Open Sans Semibold';
    font-size: 14px;
    margin-right: 15px;
}

/* input phone */
.react-tel-input.module__input-search > input{
    border: 0;
    background-color: #EBEBEB;
    width: 100%;
    border-radius: 15px;
}

.react-tel-input.module__input-search > input:focus{
    box-shadow: none;    
}

.react-tel-input.module__input-search > div.flag-dropdown{
    border-radius: 15px;
    margin-left: -15px;
}

/* table paginator page selector */
div.MuiBox-root.css-7tufii > div > div.MuiBox-root.css-1ry89yn > div > div > div > div.MuiBox-root.css-exd1zr > div{
    background-color: white;
    border-radius: 25px;
    padding-left: 5px;
    height: 33pxpx;
}

/* table paginator buttons */
div.MuiBox-root.css-7tufii > div > div.MuiBox-root.css-1ry89yn > div > div > div > div.MuiBox-root.css-192dx0w > span > button {
    background-color: white;
    border-radius: 50%;
    height: 33px;
    width: 33px;
    margin-left: 10px;
}

/* table top toolbar buttons */
div.MuiBox-root.css-7tufii > div > div.MuiBox-root.css-1fvlk2w > div.MuiBox-root.css-zrlv9q > div > div > button{
    background-color: white;
    margin-left: 10px;
}

.css-tucewo{
    justify-content: center;
}

.spinner{
    margin: auto;
    position: absolute;
}

.spinner-container {
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5); 
    display: flex;
    justify-content: center; 
    align-items: center; 
    z-index: 9999; 
}

.addbutton__container{
    width: 72px;
    height: 72px;
    margin-left: auto;
    margin-right: 0px;
    margin-bottom: 20PX;
}

.password-input{
    display: flex;
}

.password-input__icon{
    margin-top: 5px;
}

.inputs-row__no-justified{
    display: flex;
    gap: 20px;
}

.inputs-row__centered{
    display: flex;
    gap: 20px;
    justify-content: center;
}

.input-information__row{
    display: flex;
}

.error-alert{
    font-style: italic;
    color: darkred;
    text-align: center;
}

.input-wide{
    width: 90%;
}

.table-container{
    width: 100%;
    box-sizing: content-box; 
}

.date-filter__button{
    display: inline-block;
    height: 40px;
    width: 110px;
    align-self: center;
}

.main-container-app{
    height: 100%;
    width: 100%;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 35px;
    justify-content: space-between;
    min-height: 100vh;
    position: relative;
    overflow: auto;
}

@media (max-width: 1679px) {
    .main-container-app {
        padding-left: 1rem;
        padding-right: 2rem;
        padding-top: 2rem;
    }
  }

.main-box{
    background-color: #FFFFFFB3;
    /* width: 100%; */
    /* height: auto; */
    border-radius: 30px;
    padding: 20px;
    /* min-width: 100%; */
    min-width: 0;
    /* max-width: max-content; */
    box-sizing: border-box; 
    margin-bottom: 35px;
    flex-grow: 1;
    transition: width 0.3s ease;
}

.general-button{
    width: 126px;
    height: 40px;
    border-radius: 20px;
    border: 0;
    font-family: 'Open Sans Semibold';
    font-size: 18px;
    margin: 0;
    color: white;
}

.small-button{
    width: 100px;
    height: 25px;
    border-radius: 20px;
    border: 0;
    font-family: 'Open Sans Semibold';
    font-size: 12px;
    margin: 0;
}

.primary{
    background-color: #3698D4;
}

.danger{
    background-color: #DD4448;
}

.disabled{
    background-color: gray;
}

.session-alert{
    text-align: center;
    line-height: 0px;
}

/* body > div.fade.modal.show > div{
    justify-content: center;
    display: flex;
}

.fade.modal.show{
    display: flex;
} */



/* Modal */
.fade.modal.modal.show > div > div{
    width: fit-content;
    max-width: 2000px;
    min-width: 760px;
    border-radius: 20px;
    padding: 50px 90px;
    margin: auto;
    justify-content: center;
}

/* Modal Footer */
.modal-footer{
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    border: 0;
    padding-top: 40px;
}
/* Modal Title */
.modal-header > div{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color:#6A6A6A;
    font-size: 24px;
    font-weight: bold;
}
/* Modal Header */
.modal-header{
    border: 0;
}

/* Modal tabs */
.modal-tabs{
    font-family: 'Open Sans Semibold';
    font-size: 14px;
}

.modal-tabs .nav-link {
    color: #135894;
}

.search-input__box{
    border: 0;
    background-color: white;
    border-radius: 15px;
    height: 35px;
    padding-left: 15px;
    padding-right: 15px;
    width: 440px;
    align-items: center;
    display: flex;
    gap: 10px
}

.search-input:focus{
    outline: none;
}

.search-input{
    border: 0;
    width: 100%;
    font-family: 'Open Sans Semibold';
    font-size: 20px;
}

.search-input::placeholder{
    font-family: 'Open Sans Semibold';
    font-size: 20px;
}

.main-box__submenu-element{
    border: 0;
    border-radius: 15px;
    height: 35px;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
    display: flex;
    cursor: pointer;
    font-family: 'Open Sans Semibold';
    font-size: 20px;
    color:#6A6A6A;
}

.active{
    background-color: white;
}

.inactive{
    background-color: #D7D7D7;
}

.unchecked_box{
    width: 25px;
    height: 25px;
    border: 2px solid #6A6A6A;
}

.checkbox{
    cursor: pointer; 
    width: 25px;
    height: 25px;
}

.modal-dialog-centered{
    justify-content: center;
}

.custom-dropdown {
    position: relative; 
    display: inline-block;
}

.custom-dropdown select,
.custom-dropdown-modal{
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OC43MzQiIGhlaWdodD0iNDguNzM0IiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGQ9Ik0xMiAxOGwtNy05aDE0eiIvPjwvc3ZnPg==') no-repeat right 10px center;
    background-size: 16px;
    padding: 8px 20px 8px 8px;
    border-radius: 4px;
    box-sizing: border-box; 
    height: auto;
    max-width: 400px;
}

.custom-dropdown-modal{
    border: 1px solid #ccc;
    margin: 15px;
}

.margin-btn{
    margin-top: 10px;
    margin-bottom: 10px;
}

.custom-dropdown select:focus,
.custom-dropdown-modal:focus{
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    outline: 0;
}

.table__checkbox{
    cursor: 'pointer'; 
    width: 25px;
}

.scrollable-list {
    max-height: 160px;
    overflow-y: auto;
}

.card__label{
    color: #6A6A6A;
    font-family: 'Open Sans Semibold';
    font-size: 14px;
}

.card__item{
    color: #6A6A6A;
    font-family: 'Open Sans Semibold';
    font-size: 12px;
}

.dashboards_container{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}