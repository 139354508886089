.date__button{
    align-self: flex-end;
}

.custom-file-input {
    display: none; 
  }
  
  .modal__file-input-button{
    display: flex;
    padding: auto;
    cursor: pointer;
    color: white;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }
  
  .custom-file-label:hover {
    background-color: #ddd;
  }
  
  .custom-file-label:active {
    background-color: #ccc; 
  }

  .modal__aligned-input{
    align-items: flex-start;
  }

  .modal__selected-file{
    align-items: center;
  }

.modal__time{
    margin-top: 1rem;
}

.commands-date-input{
  width: 10em
}