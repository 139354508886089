.input-numeric{
    width: 70px;
}

.input-numeric-box{
    width: 60px;
}

.inputs-row{
    display: flex;
    justify-content: space-between;
}

.input-name{
    align-self: flex-end;
}

.input-price{
    width: 40%;
}

.subtitle{
    border-bottom: 1px solid gray;
}

.subtitle-position{
    margin-top: 20px;
}

.dropdown{
    width: 150px;
}

.price__alert{
    font-style: italic;
    color: #490000;
}