.footer_row{
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    width: 175%;
}

.footer_total_title{
    color: gray;
    font-family: 'Open Sans Semibold';
    flex: 1;
}

.footer_total_amount{
    font-family: 'Open Sans Bold';
    flex: 1;
    color: gray;
    font-size: 16px;
}