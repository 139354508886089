.selects-container{
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
}

.input-edit-key{
    border: 0;
    background-color: #EBEBEB;
    border-radius: 15px;
    height: 2rem;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }

.language-input-label{
    font-size: 0.7rem;
    color: rgb(100, 100, 100);
    padding-left: 0.95rem;
}