.delete-message{
    color: #6A6A6A;
    font-family: 'Open Sans Semibold';
    font-size: 18px;
    text-align: center;
    margin-bottom: 1rem;
}

.delete-tenant-message{
    color: #6A6A6A;
    font-family: 'Open Sans Semibold';
    font-size: 18px;
    text-align: left;
    margin-bottom: 1rem;
}