.custom-checkbox {
    display: none;
}

.custom-checkbox + label:before {
    content: '';
    display: inline-block;
    width: 20px; /* Ancho personalizado */
    height: 20px; /* Alto personalizado */
    border: 2px solid #999; /* Borde del checkbox */
    border-radius: 4px; /* Bordes redondeados */
    background-color: white; /* Color de fondo */
    margin-right: 5px; /* Espacio entre la etiqueta y el checkbox */
}

.custom-checkbox:checked + label:before {
    background-color: #007bff; /* Cambiar el color de fondo cuando está marcado */
    border-color: #007bff; /* Cambiar el color del borde cuando está marcado */
}

.row {
    margin-top: 10px;
}

.main-container__roles{
    height: auto;
    width: 100%;
    padding-left: 250px;
    padding-right: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main-box__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-box__submenu{
    display: flex;
    gap: 10px;
}



.main-box__role-cards{
    display: flex;
    flex-flow: row wrap;
}

.permissions-table{
    margin-top: 20px;
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
}

th{
    color: #003865;
    font-family: Belleza, serif;
    font-size: 20px;
    text-align: center;
    border-bottom: 1px solid #D7D7D7;
    border-right: 1px solid #D7D7D7;
}

td:first-child{
    text-align: left;
}

td{
    text-align: center;
    border-bottom: 1px solid #D7D7D7;
    border-right: 1px solid #D7D7D7;
}

th, td{
    width: 100%;
}

th:last-child, td:last-child{
    border-right: 0;
}

th:first-child, td:first-child {
    width: auto;
    min-width: max-content;
  }

  th:not(:first-child), td:not(:first-child) {
    width: auto;
    max-width: 20%;
  }

.footer_addButton {
    justify-self: end;
    margin-bottom: 20px;
}

.modal__inline-elements{
    display: flex;
    width: auto;
    margin-bottom: 20px;
}

.modal__name-input{
    width: 50%;
    margin-right: 20px;
}

.modal__text-box{
    width: 100%;
}

.footer__button {
    justify-self: end;
    width: 126px;
    height: 40px;
    border-radius: 20px;
    align-self: end;
    margin-bottom: 20px;
}

.alert{
    position: absolute;
    width: auto;
    z-index: 9999; 
    left: 50%;
    transform: translateX(-50%);
}

.alert-modal {
    position: relative !important;
    width: auto;
    z-index: 9999 ;
    left: 0;
    right: 0;
    margin: 10px 20px;
    text-align: center;
}

.alert-main {
    margin: 10px;
    top: 0;
    height: fit-content;
}



.table-header{
    font-family: 'Open Sans Bold';
    font-weight: bold;
    font-size: 24px;
    color:#6A6A6A;
}

.table-row__text{
    font-family: 'Open Sans Regular';
    font-size: 14px;
    color:#6A6A6A;
}

.save-button{
    margin-left: auto;
    margin-top: -30px;
    margin-bottom: 40px;
    margin-right: 0;
}

.modal__inputs-row{
    display: flex;
    width: 100%;
}

.modal__input-box{
    display: flex;
    flex-direction: column;
    width: 50%;
}