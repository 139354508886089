.sub-title{
    color: #a3a3a3;
    background-color: #f7f8fa;
    padding: 5px;
    margin-left: 10px;
}

.title-with-line{
    height: 2px;
    background-color: #c8c8c8;
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
    z-index: -1;
}

.dashboard-title, .dashboard-subtitle{
    margin-bottom: 0;

}

.dashboard-subtitle{
    margin-left: 10px;
    margin-bottom: 10px;
    font-family: 'Open Sans Semibold';
    font-size: 1.2rem;
    text-shadow: 0.2px 0.2px 1px rgba(0, 0, 0, 0.3);
}

.dashboard-date-btn{
    color: #35465A;
    border-radius: 1.5rem;
    border: 2px solid #A9B3C9;
    background-color: transparent;
    margin: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.dashboard-date-btn:hover {
    background-color: #2B52A4;
    border: 2px solid #2B52A4;
    color: #ffffff;
}

.dashboard-date-btn:active {
    transform: translateY(2px);
}

.dashboard-date-btn.active {
    background-color: #2B52A4;
    border: 2px solid #2B52A4;
    color: #ffffff;
}

.dashboard-settings{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    cursor: pointer;
}

.dashboard-container{
    display:  flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: fit-content;
    padding: 10px;
}

.dashboard-box-date{
    max-width: 250px;
    padding: 10px;
    background-color: #FFFFFFB3;
    border-radius: 10px;
    margin-bottom: 10px;
}

.dashboard-btn-container{
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
}

@media screen and (max-width: 768px) {
    .dashboard-box-date {
        max-width: none; 
        width: 100%;
    }
}

.scrollable-modal-body {
    max-height: 400px;
    overflow-y: auto;
}

.iframe-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 600px;
    max-width: 100%;
    overflow: hidden;
    position: relative;
}

.iframe-container iframe {
    width: 90%;
    height: 90%;
    border: none;
}

.remove-design svg{
    stroke: none !important;
}

.division{
    width: 33%;
}

.graph_container{
    display: flex;
    justify-content: space-between;
}

.container-buttons {
    display: flex;
    justify-content: end;
    margin-top: 30px;
    margin-bottom: 45px;
}

.container-buttons button {
    margin-right: 20px;
}

.Submit_btn{
    margin-top: 10px;
    width: 100%;
}

.button-dashboard {
    display: flex;
    color: #6A6A6A;
    position: relative;
    white-space: nowrap;
    align-self: center;
    height: 35px;
    width: 126px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: 'Open Sans Semibold';
    background-color: #D7D7D7;
    border-radius: 30px;
    border: 1px solid #6A6A6A;
    overflow: visible;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.button-dashboard:hover {
    background-color: #BFBFBF;
    color: #f2f2f2;
}

.button-dashboard:active {
    background-color: #A6A6A6;
    color: #f2f2f2;
}

.data_label{
    margin-left: 20px;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 30px;
    min-width: 20%;
    width: fit-content;
    border-radius: 1.5rem;
    border: 2px solid #A9B3C9;
}

.date{
    margin-left: 10px;
}

table td:first-child {
    text-align: center;
}

.chart-container {
    position: relative;
    transition: all 0.3s ease;
    width: 100%;
    height: 350px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
}

.expanded {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    padding: 20px;
    background: white;
    border-radius: 0;
}

.chart-header {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 10px;
}

.expand-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
}

.select-dates-section {
    display: flex;
    gap: 100px;
}

.select-dates-text {
    font-family: 'Open Sans Semibold';
    color: #6A6A6A;
    align-self: center;
    width: 150px;
}

.active {
    font-weight: bolder;
    font-family: 'Open Sans Bold';
}

.date-field {
    width: 300px;
    justify-content: center;
}

.droppable-wrapper {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);  
    padding: 10px;  
    border-radius: 25px;  
    background-color: white;  
    width: 18rem;
    display: none;
}

.dashboard-more-cards{
    margin-left: auto;
    margin-right: auto;
    text-align: center; 
    width: 100%;
    font-family: 'Open Sans Semibold';
    font-size: 1.2rem;
    text-shadow: 0.2px 0.2px 1px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.cards-container{
    display: flex;
    width: 100%;
    gap: 1rem;
}

.cards-container.show-droppable .droppable-wrapper {
    display: block;
}

.main-droppable{
    flex-grow: 1; /* Se ajusta para ocupar el espacio restante */
    transition: width 0.3s ease;
}