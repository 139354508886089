.add-button{
    width: 62px;
    height: 62px;
    background-color: #3698D4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-bottom: 35px;
    cursor: pointer;
    padding-bottom: 10px;
}
.add-button:hover{
    background-color: #0e527c
}
.add-button:active{
    background-color: #b1d3e9;
}
.add-button__icon{
    color: white;
    font-size: 50px;
}
