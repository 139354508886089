.navbar {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 11;
}

.navbar__logo{
    height: 90%;
    margin-left: 10px;
}

.navbar__notification-icon{
    margin-left: auto;
    font-size: 20px;
}

.navbar__avatar{
    height: 4rem;
    margin: auto;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}

.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.closeSideBar{
    margin-top: 7px;
}

.nav-menu {
    /* width: 140px; */
    width: 7rem;
    height: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 10px;
    left: -100%;
    transition: 850ms;
    z-index: 2;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #F5F5F5;
    border-top-right-radius: 20px; 
    border-bottom-right-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1679px) {
  .nav-menu {
    width: 6rem;
  }
}



.nav-menu.active {
    left: 0;
    /* overflow-y: auto; */
}

.nav-menu-items {
    width: 100%;
    
}

.SidebarWrap{
    width: 100%;
    
}

.avatar__group{
    height: 100%;
    position: relative;
}

.navbar-dropdown{
    width: max-content;
    background-color: white; 
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    right: 0;
    /* overflow-x: auto; */
    position: absolute;
}

.menu__icon{
    height: 2rem;
    color: '#5b5b5b';
    font-size: '6rem',
}

@media (max-width: 1679px) {
  .menu__icon {
    height: 1.5rem;
  }
}

/* //////////////////// */

/* .nav-menu {
    width: 250px;
    background-color: #15171c;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
  } */
  
  .nav-menu-items {
    width: 100%;
    list-style: none;
    padding: 0;
    /* align-items: center;
    justify-items: center;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto; */
  }
  
  .nav-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 8px 16px; */
    color: #5b5b5b;
    text-decoration: none;
    text-align: center;
    height: 7rem;
  }

  @media (max-width: 1679px) {
    .nav-text {
      height: 5rem;
      font-size: 0.85rem;
    }
  }
  
  .nav-text a {
    text-decoration: none;
    color: #5b5b5b;
    width: 100%;
  }
  
  .nav-text a:hover,
  .nav-text a:focus,
  .nav-text a:active{
    background-color: #D7D7D7;
    height: 120px;
    
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-top-right-radius: 20px; 
    border-bottom-right-radius: 20px;
  }
  
  .sub-menu {
    margin-left: 0;
    width: 17rem;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 10px;
    background-color: #F5F5F5;
    border-top-right-radius: 20px; 
    border-bottom-right-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding-left: 7rem;
    padding-top: 20px;
    z-index: 1;
    transition: opacity 850ms, transform 850ms;
    opacity: 0;
    transform: translateX(-100%);
    }

    @media (max-width: 1679px) {
      .sub-menu {
        width: 14rem;
        padding-left: 5.5rem;
      }
    }

    .sub-menu.show {
    opacity: 1;
    transform: translateX(0);
    }
  

  .nav-subtitle {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    color: #5b5b5b;
    text-decoration: none;
    font-weight: bold;
  }

  @media (max-width: 1679px) {
    .nav-subtitle {
      font-size: 0.85rem;
    }
  }
  
  .nav-subtext {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    color: #5b5b5b;
    text-decoration: none;
    padding-left: 35px;
  }

  @media (max-width: 1679px) {
    .nav-subtext {
      font-size: 0.85rem;
    }
  }
  
  .nav-subtext a {
    text-decoration: none;
    color: #f5f5f5;
    width: 100%;
  }
  
  .nav-subtitle:hover,
  .nav-subtitle:focus,
  .nav-subtitle:active,
  .nav-subtitle.active,
  .nav-subtext:hover,
  .nav-subtext:focus,
  .nav-subtext:active,
  .nav-subtext.active {
    background-color: #D7D7D7;
  }
  
  .nav-link{
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    height: 100%;
  }

  /* .triangle {
    width: 0; 
    height: 0; 
    border-left: 50px solid #F5F5F5;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent; 
    position: absolute;
    top: 50%;
    left: 140px; 
    transform: translateY(-50%);
    z-index: 2;
    
}
.triangle::before, .triangle::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
}

.triangle::before {
    border-bottom: 25px solid #F5F5F5;
    top: -25px;
    box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.3); 
}

.triangle::after {
    border-top: 25px solid #F5F5F5;
    bottom: -25px;
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.3); 
}

.triangle-container {
    height: 100vh;
    display: flex;
    justify-content: flex-start; 
    align-items: center; 
} */

.submenu__icon{
    color:#5b5b5b
}