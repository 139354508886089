.overlay {
    background-color: white; 
    width: fit-content;
    border-radius: 20px;
    z-index: 9999; 
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 

    padding-top: 20px;
  }

  .overlay__link{
    cursor: pointer;
    font-family: 'Open Sans Semibold';
    font-weight: light;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    color: #6A6A6A
  }

  .overlay__link:hover,
  .overlay__link-red:hover{
    background-color: #EBEBEB;
  }

  .overlay__link-red{
    cursor: pointer;
    font-family: 'Open Sans Semibold';
    font-weight: light;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    color: #DD4448;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .separated {
    border-bottom: 1px solid #ccc
  }

  .separated:last-child {
    border-bottom: none; /* Quitar la línea del último elemento */
  }
  
  .close-button {
    position: absolute;
    top: 0px;
    right: 5px;
    margin-bottom: 10px;
    border: 0;
    background: none;
    cursor: pointer;
  }
  