.products__subtitle{
    margin-top: 20px;
}

.subtitle__row{
    display: flex;
    align-items: flex-end;
}

.subtitle__icon{
    margin-bottom: 13px;
    margin-left: 5px;
    cursor: pointer;
}

.addbutton__container{
    margin-top: 20px;
}

.restriction-zone__inputs-row{
    display: flex;
    gap: 20px;
}