.alert__red{
    min-width: 480px;
    min-height: 60px;
    border: 1px solid #DD4448;
    border-radius: 30px;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    display: flex;
    gap: 20px;
    color: #DD4448;
    font-family: 'Open Sans Semibold';
    font-size: 18pt;
    white-space: 'pre-line'
  }