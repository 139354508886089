.table-header, .table-cell {
    min-width: 100px;
    width: 10%;
    max-width: 200px;
    word-wrap: break-word;
    text-align: center;
    font-size: 16px;
}

.table-header{
    font-family: 'Open Sans Bold';
    font-size: 0.875rem;
}

.table-column-active {
    width: 15%;
}

.table-column-total {
    width: 15%;
}

.table-column-alarms {
    width: 15%;
}

.table-column-dynamic {
    width: auto;
}