.main-container__create-user{
    height: auto;
    width: 760px;
    margin-left: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.module__title{
    align-self: flex-start;
}

.avatar{
    width: 164px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.avatar__image{
    width: 164px;
    border-radius: 50%;
    
}

.inputs__inline-elements{
    display: flex;
    gap: 20px;
    flex-wrap: nowrap;
    width: 100%;
}

.inputs__element{
    width: 720px;
    border-radius: 15px;
}

.inputs__inline-element{
    width: 100%;
}

.inputs__invalid-number-alert{
    color:darkred;
    font-style: italic;
    font-weight: lighter;
    margin-left: 20px;
}

.inputs__notifications{
    width: 350px;
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
}

.input__box{
    flex: 1;
}

.footer{
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
}

.reset-link{
    cursor: pointer;
}