.main-container {
  align-items: center;
  /* background: linear-gradient(to bottom right, #d3e0eb 0%, #eee 50%)
              bottom right / 50% 50% no-repeat,
            linear-gradient(to bottom left, #d3e0eb 0%, #eee 50%) bottom left /
              50% 50% no-repeat,
            linear-gradient(to top left, #d3e0eb 0%, #eee 50%) top left / 50%
              50% no-repeat,
            linear-gradient(to top right, #d3e0eb 0%, #eee 50%) top right / 50%
              50% no-repeat; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: auto;
  height: 100vh;
  width: 100%;
  background-image: url("/public/img/Light_Background.png");
  background-size: cover; /* Ajusta la imagen para cubrir todo el contenedor */
  background-position: center; /* Centra la imagen */
  }
  @media (max-width: 991px) {
    .container {
      padding: 0 20px;
    }
  }
  .login__container {
    display: flex;
    width: 560px;
    max-width: 100%;
    flex-direction: column;
    background-color: #f5f5f5;
    filter: drop-shadow(0px 40px 50px rgba(0, 0, 0, 0.25));
    border-radius: 30px;
    padding-bottom: 40px;
  }
  @media (max-width: 991px) {
    .login__container {
      margin: 40px 0;
    }
  }
  .login__background {
    display: flex;
    flex-direction: column;
    filter: drop-shadow(0px 40px 50px rgba(0, 0, 0, 0.25));
    overflow: visible;
    position: relative;
    display: flex;
    min-height: 592px;
    width: 100%;
    padding: 18px 37px 50px;
    background-color: #f0f0f0;
    border-radius: 30px;
    border: 0;
  }
  .login__logo {
    aspect-ratio: 0.78;
    object-fit: contain;
    object-position: center;
    height: 203px;
    overflow: hidden;
    align-self: center;
    /* max-width: 100%; */
    z-index: 10;
    margin-bottom: 70px;
  }
  .login__input {
    position: relative;
    border-radius: 30px;
    background-color: #D7D7D7;
    align-self: stretch;
    display: flex;
    align-items: center;
    
    height: 60px;
    
  }

  .login__inputs-group{
    margin: 40px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .login__input--new-password {
    position: relative;
    border-radius: 30px;
    background-color: #4d729d;
    align-self: stretch;
    display: flex;
    margin-top: 52px;
    justify-content: space-between;
    gap: 20px;
  }
  .login__input-container--below {
    position: relative;
    border-radius: 30px;
    background-color: #4d729d;
    align-self: stretch;
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    justify-content: center;
  }

  .login__input-background--below {
    border-radius: 30px;
    background-color: #4d729d;
    display: flex;
    width: 100%;
    padding-right: 46px;
    justify-content: space-between;
    gap: 20px;
  }
  .login__input--below {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  .login__bottom {
    position: relative;
    align-self: stretch;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 560px;
    align-items: start;
    justify-content: space-between;
    gap: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .login__bottom--centered {
    position: relative;
    align-self: stretch;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 560px;
    align-items: start;
    justify-content: center;
    gap: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .login__bottom-remember {
    display: flex;
    gap: 6px;
  }
  .login__bottom-remember-text {
    color: rgba(0, 0, 0, 0.75);
    align-self: start;
    flex-grow: 1;
    white-space: nowrap;
    font: normal 400 14pt Inter, -apple-system, 'Open Sans Semibold';
  }
  @media (max-width: 991px) {
    .login__bottom-remember-text {
      white-space: initial;
    }
  }
  .login__bottom-forgot {
    color: rgba(0, 0, 0, 0.75);
    font: italic 400 14pt Inter, -apple-system, 'Open Sans Semibold';
    margin-left: auto;
    margin-right: auto;
    text-decoration: underline;
    cursor: pointer;
  }
  .login__bottom--sms-code { 
    color: rgba(0, 0, 0, 0.75);
    margin-top: 68px;
    white-space: nowrap;
    font: italic 400 20px Inter, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .login__bottom--sms-code{
      margin-top: 40px;
      white-space: initial;
    }
  }
  .login__icon {
  width: 50px;
  height: 50px;
  margin-left: 5px;
}
.login__icon-right {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  margin-left: auto;
}
.login__icon-container{
    border-radius: 30px 0px 0px 30px;
  background-color: #003865;
  display: flex;
  height: 88px;
  width: 88px;
  flex-direction: column;
  z-index: 9999;
}

.login__form {
  background-color: transparent;
  z-index:9998;
  border: none;
  color: #6A6A6A;
  margin-left: 20px;
  margin-right: 20px;
  font-family: 'Open Sans Semibold';
  font-size: 18pt;
}
.login__form:focus {
    background-color: transparent;
    z-index:9998;
    border: none;
    color: #6A6A6A;
    margin-left: 20px;
    outline: none;
    font-family: 'Open Sans Semibold';
    font-size: 18pt;
}
.login__form:active {
  background-color: transparent;
  z-index:9998;
  border: none;
  color: #6A6A6A;
  margin-left: 20px;
  font-family: 'Open Sans Semibold';
  font-size: 18pt;
}
.login__form:-webkit-autofill{
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px #D7D7D7 inset;
  z-index:9998;
  border: none;
  color: #6A6A6A;
  margin-left: 20px;
  font-family: 'Open Sans Semibold';
  font-size: 18pt;
}

.login__form:-webkit-autofill:focus {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px #D7D7D7 inset;
  z-index:9998;
  border: none;
  color: #6A6A6A;
  margin-left: 20px;
  font-family: 'Open Sans Semibold';
  font-size: 18pt;
}
.login__form:-webkit-autofill:hover {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0px 1000px #D7D7D7 inset;
  z-index:9998;
  border: none;
  color: #6A6A6A;
  margin-left: 20px;
  font-family: 'Open Sans Semibold';
  font-size: 18pt;
}

.login__form::placeholder{
  font-family: 'Open Sans Semibold';
  font-size: 18pt;
  color: #6A6A6A;
}

.login__form::-ms-reveal {
  display: none;
}

.login__form::-ms-clear {
  display: none;
}

.login__elements-group--sms-code {
  position: relative;
  align-self: stretch;
  display: flex;
  margin-top: 43px;
  flex-direction: column;
  align-items: center;
  padding: 0 37px;
}
@media (max-width: 991px) {
  .login__elements-group--sms-code {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
  }
}
.login__elements-group--forgot-password {
  display: flex;
  width: 546px;
  max-width: 100%;
  flex-direction: column;
  margin: 110px 0 77px;
}
@media (max-width: 991px) {
  .login__elements-group--forgot-password {
    margin: 40px 0;
  }
}
.login__elements-group--reset-password {
  display: flex;
  width: 546px;
  max-width: 100%;
  flex-direction: column;
}
.login__title {
  color: rgba(0, 0, 0, 0.75);
  align-self: stretch;
  font: 700 32px Inter, sans-serif;
}
@media (max-width: 991px) {
  .login__title {
    max-width: 100%;
  }
}
  .button-light-blue {
    display: flex;
    color: white;
    position: relative;
    white-space: nowrap;
    filter: drop-shadow(0px 40px 50px rgba(0, 0, 0, 0.25));
    align-self: center;
    height: 60px;
    width: 200px;
    justify-content: center;
    align-items: center;
    font-size: 18pt;
    font-family: 'Open Sans Semibold';
    background-color: #3698D4;
    border-radius: 30px;
    overflow: visible;
    cursor: pointer;
  }

  .button-light-blue:hover {
    background-color: #0e527c;
  }
  .button-light-blue:active {
    background-color: #b1d3e9;
  }
  @media (max-width: 991px) {
    .button {
      white-space: initial;
      padding: 0 20px;
    }
  }

  .option-text{
    color: black
  }

  .tenants-title{
    margin-top: 40px;
  }

  .tenants__dropdown{
    width: 560px;
    border-radius: 30px;
    font: 2rem 'Open Sans Semibold';
    color: #6A6A6A;
    height: 65px;
    margin-bottom: 40px;
  }

  .tenants__dropdown .tenants__dropdown-option{
    font-size: 2rem;
  }

