.tvmversion__input{
    align-self: flex-end;
}

.inputs-row-last{
    display: flex;
}

.active-input{
    margin-left: 40px;
}

.devices-title{
    margin-left: 10px;
}

.active-title{
    color: #6A6A6A !important; 
    text-decoration: underline;
    cursor: pointer;
    
}

.inactive-title{
    color: #D7D7D7 !important;
    cursor: pointer;
}

.modal-header{
    gap: 10px;
}

.coins-input{
    display: flex;
    flex-wrap: wrap;
}

.coin-box{
    width: 110px;
    justify-content: space-between;
    display: flex;
    margin-right: 20px;
}

.alarm-box{
    min-width: 26rem;
    justify-content: space-between;
    display: flex;
    margin-right: 1rem;
    margin-bottom: 10px;
}

.modal-subtitle{
    margin-top: 15px;
}

.modal-range{
    width: 300px;
}

.modal-range__value{
    align-self: center;
}

.modal-range__box{
    display: flex;
    gap: 10px;
}

.modal-range__control{
    font-weight: bolder;
    cursor: pointer;
}

.modal__denomination{
    color: #6A6A6A;
    font-family: 'Open Sans Regular';
    font-size: 14px;
}

.modal__checkbox{
    cursor: 'pointer'; 
    width: 15px;
}

.modal__port-input{
    border: 0;
    background-color: #EBEBEB;
    border-radius: 15px;
    height: 25px;
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
  }

.modal__port-input:focus{
    outline: none;
}

.modal__tvm-number-input{
    width: 6rem;
}