.droppable{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
    padding: 20px 10px;
    min-height: 110px;
    /*background-color: #eeeeeeb3;
    box-shadow: 0px 5px 3px #e6e6e6;*/
    border-radius: 5px;
    min-width: 110px;
    margin-bottom: 10px;
}

.droppable-over {
    background-color: #eeeeeeb3;
    border: 2px dashed #000000;
}

.droppable-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.toggle-drag-button {
    background: none;
    border: none; 
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #5d5d5d;
}

.toggle-drag-button svg {
    margin-right: 0.5rem;
}

.toggle-drag-button-text {
    display: none;
}

.dragAndDrop-cards-btn{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
}

.table__icon {
    transition: transform 0.4s ease, box-shadow 0.4s ease;
}