.modal-scroll-container {
    max-height: 60vh; 
    overflow-y: auto;  
    padding-right: 10px; 
  }
  
  .modal-scroll-container::-webkit-scrollbar {
    width: 8px; 
  }
  
  .modal-scroll-container::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 5px; 
  }
  
  .modal-scroll-container::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  
  .modal-scroll-container {
    scrollbar-width: thin;
    scrollbar-color: #888 transparent;
  }
  