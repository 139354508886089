.version-footer{
    margin-top: auto;
    padding-top: 1rem;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    font-family: "Open Sans Regular";
    color: rgb(80, 80, 80);
    font-size: 0.8rem;
    border-top: 1px solid rgb(167, 167, 167);
    width: 50%;
}