.paginator{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    border-bottom: 2px solid #D7D7D7;
    border-top: 2px solid #D7D7D7;
    margin-left: auto;
    margin-top: 35px;
    height: 60px;
}

.paginator__pages{
    font-family: 'Open Sans Regular';
    font-size: 14px;
}

.paginator__button{
    margin-left: 10px;
    height: 33px;
    cursor: pointer;
}