.role-card{
    width: 440px;
    height: 160px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    margin-top: 20px;
    margin-right: 20px;
    border-radius: 4px;
    padding: 20px;
    border-radius: 15px;
    background-color: white;
}

.role-card__header{
    display: flex;
    align-items: center;
    gap:10px;
    width: 100%;
}

.role-card__header-icon{
    height: 30px;
}

.role-card__header-title{
    font-family: 'Open Sans Bold';
    font-size: 24px;
    color:#6A6A6A;
}

.role-card__header-status{
    fill: green;
    margin-left: auto;
}

.role-card__header-status__inactive{
    fill: red;
    margin-left: auto;
}

.role-card__edit-icon{
    margin-left: auto;
    cursor: pointer;
}

.role-card__description{
    height: 48px;
    font-family: 'Open Sans Regular';
    font-size: 14px;
    margin-left: 2.5rem;
    margin-top: 1rem;
}

.role-card__footer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.role-card__members{
    border: 0;
    border-radius: 10px;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    display: flex;
    cursor: pointer;
    font-family: 'Open Sans Regular';
    font-size: 14px;
    color:#6A6A6A;
    background-color: #D7D7D7;
    gap: 25px;
}

.role-card__footer-icon{
    height: 13px;
}

.members-modal .modal-content {
    min-width: auto !important;
    padding: 25px 25px !important;
}

.members-modal-message{
    font-family: 'Open Sans Regular';
    font-size: 14px;
}