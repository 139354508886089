.email-assign__row{
    margin-top: 20px;
}

.email-assign__checkbox{
    margin-right: 10px;
}

.custom-modal {
    width: auto;
    min-width: fit-content;
    overflow: auto;
}

.qr-modal__access{
    text-align: center;
}

